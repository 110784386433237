<p-card>
    <div class="content-wrapper">
        <h2 style="margin-top: 1rem">
            Das Für&shy;ei&shy;nan&shy;der-Por&shy;tal für Eus&shy;kir&shy;chen
        </h2>
        <br />
        <p style="font-size: 1.3em" class="lead">
            Während der nächsten Monate müssen wir mit den Auswirkungen der
            Corona-Krise leben. Die Zahl der Betroffenen und damit auch die Zahl
            der Menschen, die Unterstützung benötigen, wird stetig ansteigen.
            Menschen, die nicht auf Familie oder Freunde zurückgreifen können
            und auch Menschen, die zu einer Risikogruppe gehören. Immer mehr
            Menschen in unserem unmittelbaren Umfeld sind auf fremde Hilfe
            angewiesen. Die Webseite Euskirchen Hilft wurde als erweiterte
            Nachbarschaftshilfe eingerichtet.
        </p>
        <br />
        <p style="font-size: 1.3em" class="lead">
            Die Idee: Kleine Hilfen anbieten, die oftmals Großes bewirken.
            Einkaufen, Kinder betreuen, Hunde ausführen, Dinge teilen,
            miteinander gegen die Einsamkeit telefonieren. Es geht ganz einfach:
            Registrieren und ankreuzen, wie man helfen will. Dazu Telefonnummer
            oder E-Mail-Adresse angeben, damit man Kontakt mit Ihnen aufnehmen
            kann.
        </p>
        <br />
        <p style="font-size: 1.3em" class="lead">
            Eine Bitte: Beachten Sie die Richtlinien und Verhaltensempfehlungen:
            Abstand zueinander halten, regelmäßiges Desinfizieren der Hände und
            die Vorgaben der Behörden. Vielen Dank und bleiben Sie gesund.
        </p>
        <br />

        <p>
            <button
                (click)="auth.login()"
                pButton
                type="button"
                [label]="
                    auth.loggedIn
                        ? 'Helferprofil bearbeiten'
                        : 'Jetzt Helfer werden!'
                "
                style="width: 100%; font-size: 2em; font-weight: bold; margin: 1em 0 0"
            ></button
            ><button
                *ngIf="auth.loggedIn"
                (click)="auth.logout()"
                pButton
                type="button"
                label="Ausloggen"
                style="width:  100%; font-size: 2em; font-weight: bold; margin: 0.25rem 0; background-color: #a90000 !important; color: #fff !important;"
            ></button>
        </p>
    </div>
    <!--<div class="ui-g">
        <div class="ui-g-5 ui-sm-12">
            <a
                target="_blank"
                href="https://www.infektionsschutz.de/fileadmin/infektionsschutz.de/Downloads/Merkblatt-Verhaltensempfehlungen-Coronavirus.pdf"
                pButton
                type="button"
                label="Verhaltensempfehlungen"
                id="secondButton"
            ></a>
        </div>
    </div>-->
</p-card>
<p-card
    ><p-header>
        <div class="content-wrapper">
            <h2 style="margin-top: 1em">Hel&shy;fer fin&shy;den</h2>
            <p style="font-size: 1.3em">
                Gebe einfach deine Adresse ein und finde Helfer in der Nähe!
            </p>
        </div>
    </p-header>
    <cna-geo-search-bar
        (pointSelected)="onPointSeleceted($event)"
        searchPlaceholder="Adresse eingeben und Helfer finden!"
    ></cna-geo-search-bar>
    <cna-options-check-box-bar></cna-options-check-box-bar>
    <cna-map></cna-map>
</p-card>
<p-card>
    <div class="content-wrapper">
        <h2 style="margin-top: 1rem">
            Der Eus&shy;kir&shy;chen&shy;hilft Fly&shy;er
        </h2>

        <p style="font-size: 1.3rem; margin-top: 1em">
            Einige Ihrer Nachbarn haben vielleicht kein Internet. Wir haben
            einen Flyer erstellt, welcher in der Nachbarschaft verteilt und
            aufgehängt werden kann. Hier den Flyer downloaden.
        </p>
        <p>
            <a
                href="https://gnasandbox.blob.core.windows.net/ulmhilft/Euskirchen_hilft_Flyer (verschoben) 2.pdf"
                target="_blank"
            >
                <button
                    pButton
                    type="button"
                    label="Flyer downloaden!
     "
                    style="width: 100%; font-size: 2em; font-weight: bold; margin: 1em 0"
                ></button
                ></a>
        </p>
    </div>
</p-card>
<p-card>
    <div class="content-wrapper">
        <h2 style="margin-top: 1rem">
            Fragen?
        </h2>
        <p style="font-size: 1.3rem">
            Wenn Sie organisatorische Fragen zu Euskirchenhilft haben, können Sie uns gerne eine E-Mail schreiben: euskirchenhilft@gmail.com Wir werden so schnell wie möglich antworten und versuchen weiterzuhelfen.
        </p>
        <div
            class="ui-g ui-fluid"
            style="width: 100%; padding-left: 0; padding-right: 0; "
        >

            <div
                class="ui-g-12 ui-sm-12"
                style="padding-left: 0; padding-right: 0"
            >
                <button
                    (click)="writeEmail()"
                    pButton
                    type="button"
                    label="euskirchenhilft@gmail.com"
                    style="width: 100%; font-size: 2em; font-weight: bold; margin: 1em 0"
                ></button>
            </div>
        </div>
    </div>
</p-card>
<p-card>
    <div class="content-wrapper">
        <h2 style="margin-top: 1rem">
            Das On&shy;line-Por&shy;tal Eus&shy;kir&shy;chen&shy;hilft
        </h2>

        <p style="font-size: 1.3rem; margin-top: 1em">
            Das Online-Portal Euskirchenhilft wurde von der <a href="https://www.badewelt-euskirchen.de/de/">Badewelt Euskirchen</a> gemeinsam mit der Digitalagentur <a href="https://www.rrooaarr.com/">rrooaarr interactive solutions</a> aus Ulm eingerichtet.
        </p>

    </div>
</p-card>

