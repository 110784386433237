<p-card>
    <p-header>
        <div class="ui-g ui-fluid">
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <h2>Werde Helfer!</h2>
                <br />
                <p class="bigFont lead">
                    Wenn du gesund bist und unseren Mitmenschen im Rahmen von
                    #euskirchenhilft deine Hilfe anbieten möchtest, kannst du dich hier
                    als Helfer in das Formular eintragen lassen.
                </p>
                <p class="bigFont">
                    Nach dem Absenden werden die Angaben veröffentlicht und
                    somit Hilfesuchenden zugänglich gemacht. Bitte gebe nur die
                    Daten an, die auf der Helferliste eingesehen werden dürfen
                    und zum Zwecke der Vermittlung weitergegeben werden dürfen.
                </p>
                <p class="bigFont">
                    Bitte halte folgende Regeln ein:
                </p>
                <ul class="bigFont">
                    <li>keine Werbung</li>
                    <li>
                        keine illegalen/gefährlichen Angebote (auch Eigenschutz
                        beachten!)
                    </li>
                    <li>nur seriöse Angebote</li>
                    <li>nur sachliche / "meinungslose" Einträge</li>
                </ul>
            </div>
        </div>
    </p-header>
    <div class="ui-g ui-fluid" style="margin-top: -3.5rem">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <h3>Wie heißt du?</h3>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"
                        ><i class="pi pi-user" style="line-height: 1.25"></i
                    ></span>
                    <input
                        type="text"
                        pInputText
                        placeholder="Dein Vor- oder Spitzname"
                        formControlName="nick"
                    />
                </div>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <h3>Wie kann man dich er&shy;rei&shy;chen?</h3>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0 ui-md-6 ">
                <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"
                        ><i class="pi pi-envelope" style="line-height: 1.25"></i
                    ></span>
                    <input
                        type="text"
                        pInputText
                        placeholder="Emailadresse"
                        formControlName="email"
                    />
                </div>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-md-6 ui-sm-12 ui-sm-offset-0 ">
                <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"
                        ><i class="pi pi-bell" style="line-height: 1.25"></i
                    ></span>
                    <input
                        type="text"
                        pInputText
                        placeholder="Handy- oder Festnetznummer (freiwillig)"
                        formControlName="phone"
                    />
                </div>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <h3>Wie kannst du Hel&shy;fen?</h3>
                <br />
                <div class="ui-g-3" *ngFor="let option of options">
                    <p-checkbox
                        [value]="option.value"
                        [label]="option.label"
                        name="services"
                        [formControl]="form.controls['services']"
                    >
                    </p-checkbox>
                </div>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <h3>Wo kannst du Hel&shy;fen?</h3>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <cna-geo-search-bar
                    (pointSelected)="this.location = $event.position"
                    searchPlaceholder="Deine Adresse, dein Wohnort..."
                ></cna-geo-search-bar>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <p-checkbox
                    binary="true"
                    label="Alle angegebenen Daten dürfen auf der Website unverschlüsselt in einer Liste veröffentlicht werden, damit ein Kontakt zu den Hilfsbedürftigen vermittelt werden kann. Ich bin volljährig, akzeptiere die Datenschutzerklärung und habe die Hinweise zum Angebot gelesen und verstanden. Ich habe außerdem zur Kenntnis genommen, dass ich die Bereitstellung meiner persönlichen Daten auf dieser Webseite sowie die Registrierung jederzeit mit Wirkung für die Zukunft widerrufen kann. Der Widerruf kann im Login-Bereich durch Klicken auf den Link „Meine Daten löschen!“ oder schriftlich formlos an die in der Datenschutzerklärung angegebene Mailadresse erfolgen."
                    required
                    formControlName="privacy"
                >
                </p-checkbox>
            </div>
            <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
                <button
                    pButton
                    label="Ich möchte Helfen!"
                    type="submit"
                    [disabled]="!form.valid"
                    style="background-color: #ffe400; border-color: #ffe400"
                ></button>
            </div>
        </form>
        <div class="ui-g-6 ui-g-offset-3 ui-sm-12 ui-sm-offset-0">
            <a style="cursor: pointer" (click)="onDelete()"
                >Meine Daten Löschen!</a
            >
        </div>
    </div>
</p-card>
<p-toast></p-toast>
