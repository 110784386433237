<div class="content-wrapper">
    <h2>Impressum</h2>
    <p>Verantwortlich f&uuml;r den Inhalt:</p>
    <p>Thermen &amp; Badewelt Euskirchen GmbH<br /> Thermenallee 1 (Stresemannstra&szlig;e 999)<br /> D-53879 Euskirchen</p>
    <p>Tel.: +49 (0)2251 / 1485-0<br /> Fax: +49 (0)2251 / 1485-485</p>
    <p>Tel. MassagePerle: +49 (0)2251 / 1485-350</p>
    <p>E-Mail: info(at)badewelt-euskirchen.de<br /> Internet: www.badewelt-euskirchen.de</p>
    <p>Vertretungsberechtigter Gesch&auml;ftsf&uuml;hrer: Edelfried Balle</p>
    <p>Registergericht: Amtsgericht Bonn<br /> Registernummer: HRB 21962<br /> Umsatzsteueridentifikationsnummer: DE275383170</p>
    <p>Verantwortlich f&uuml;r den Inhalt der Webseite:&nbsp;Edelfried Balle | Thermenallee 1 (Stresemannstra&szlig;e 999) | D-53879 Euskirchen</p>
    <p>Die Inhalte fremder Seiten, auf die www.badewelt-euskirchen.de verweist, spiegeln nicht unsere Meinung wider. Wir sind nicht f&uuml;r Inhalte fremder Seiten verantwortlich und distanzieren uns ausdr&uuml;cklich davon.</p>
    <p>Online-Streitbeilegung gem&auml;&szlig; Art. 14 Abs. 1 Verordnung (EU) Br. 524/2013 (ODR-Verordnung): Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung OS oder (Online Dispute Resolution) bereit. Die Plattform finden Sie unter <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">http://ec.europa.eu/consumers/odr/</a>. Verbraucher haben die M&ouml;glichkeit diese Plattform f&uuml;r die Beilegung ihrer Streitigkeiten im Zusammenhang mit einer Online-Bestellung zu nutzen.<br /> Zur Durchf&uuml;hrung eines Verfahrens gem&auml;&szlig; &sect; 36 Verbraucherstreitbeilegungsgesetz (VSBG) vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und auch nicht bereit.</p>
    <p>Rechtliche Beratung:<br /> Rechtsanwalt Prof. Dr. jur. Dieter Nennen<br /> <a href="http://www.nennen.de">www.nennen.de</a></p>
    <p>Realisierung:</p>
    <p>rrooaarr interactive solutions<br /> H&auml;mpfergasse 15<br /> 89073 Ulm<br /> Deutschland<br /> <a href="https://www.rrooaarr.com">www.rrooaarr.com</a></p>
    <p>&nbsp;</p>
</div>
