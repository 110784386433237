<div class="content-wrapper">
    <h2>Datenschutzerkl&auml;rung</h2>
    <p><strong>&nbsp;</strong></p>
    <ol>
        <li><strong>Name und Kontaktdaten der verantwortlichen Stelle</strong></li>
    </ol>
    <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>
    <p>Thermen &amp; Badewelt Euskirchen GmbH</p>
    <p>Thermenallee 1 (Stresemannstra&szlig;e 999)</p>
    <p>D-53879 Euskirchen</p>
    <p>E-Mail: euskirchenhilft@gmail.com</p>
    <p>Telefon: +49 (0)2251 / 1485-0</p>
    <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. &Auml;.). Sofern eine gemeinsame Entscheidung &uuml;ber die Zwecke und Mittel der Verarbeitung zusammen mit anderen Verantwortlichen erfolgt, wird in dieser Datenschutzerkl&auml;rung explizit darauf hingewiesen.</p>
    <p>&nbsp;</p>
    <ol start="2">
        <li><strong>Verarbeitung personenbezogener Daten auf dieser Webseite</strong></li>
    </ol>
    <p><u>Server-Log-Dateien</u></p>
    <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</p>
    <ul>
        <li>Besuchte Seite auf unserer Domain</li>
        <li>Datum und Uhrzeit der Serveranfrage</li>
        <li>Browsertyp und Browserversion</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>IP-Adresse</li>
    </ul>
    <p>Es findet keine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags oder vorvertraglicher Ma&szlig;nahmen gestattet.</p>
    <p>&nbsp;<u>Registrierung auf unserer Webseite (Helferformular)</u></p>
    <p><em>Direkte Registrierung auf der Webseite</em></p>
    <p>Alle Angaben im Helferformular sind freiwillig und werden &ouml;ffentlich sichtbar in ein Google Dokument geladen.</p>
    <p>Bei der Registrierung f&uuml;r die Nutzung unserer personalisierten Leistungen werden einige personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten (z. B. Telefonnummer und E-Mail-Adresse). Sind Sie bei uns registriert, k&ouml;nnen Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die M&ouml;glichkeit, bei Bedarf die bei Registrierung angegebenen Daten jederzeit zu &auml;ndern oder zu l&ouml;schen. Alle Daten werden mit Beendigung des Projekts gel&ouml;scht. Selbstverst&auml;ndlich erteilen wir Ihnen dar&uuml;ber hinaus jederzeit Auskunft &uuml;ber die von uns &uuml;ber Sie gespeicherten personenbezogenen Daten.</p>
    <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
    <p>Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den Betrieb und die Wartung unserer Website als Auftragsverarbeiter t&auml;tig werden.</p>
    <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gel&ouml;scht, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerkl&auml;rung angegebenen Kontaktdaten.</p>
    <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gew&auml;hren.</p>
    <p><em>Registrierung mit Facebook-Login</em></p>
    <p>Nutzer haben die M&ouml;glichkeit, sich auf unserer Webseite mit ihrem Facebook-Konto zu registrieren. Facebook wird betrieben von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.</p>
    <p>Zur Anmeldung werden Nutzer auf die Seite von Facebook weitergeleitet, wo sie sich mit ihren Facebook-Benutzerdaten anmelden k&ouml;nnen. Hierdurch wird das Facebook-Profil des Nutzers mit unserer Webseite bzw. den Diensten verkn&uuml;pft. Durch die Verkn&uuml;pfung erhalten wir automatisch von der Facebook Ireland Limited folgende Daten:</p>
    <ul>
        <li>Facebook-ID</li>
        <li>Facebook-Vorname</li>
        <li>Facebook-Nachname</li>
        <li>Facebook-Profilbild</li>
        <li>Facebook-E-Mail-Adresse</li>
        <li>Geschlecht</li>
        <li>Land</li>
        <li>Sprache</li>
    </ul>
    <p>Diese Informationen werden f&uuml;r die Registrierung und die Erstellung eines Profils auf unserer Webseite verwendet. Die Facebook-ID wird ben&ouml;tigt, um dem Nutzer auch nach der ersten Anmeldung weitere Logins auf unserer Webseite &uuml;ber die Facebook-Login Funktion zu erm&ouml;glichen.</p>
    <p>Weitere Informationen zu Facebook-Login und den Privatsph&auml;re-Einstellungen sind online verf&uuml;gbar <a href="https://www.facebook.com/about/privacy/update">Datenrichtlinie</a>&nbsp;und den&nbsp;<a href="https://www.facebook.com/legal/terms">Nutzungsbedingungen</a> der Facebook Ireland Limited.</p>
    <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
    <p>Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den Betrieb und die Wartung unserer Website als Auftragsverarbeiter t&auml;tig werden.</p>
    <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gel&ouml;scht, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerkl&auml;rung angegebenen Kontaktdaten.</p>
    <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gew&auml;hren.</p>
    <p><em>Registrierung mit Google-Login</em></p>
    <p>Wir bieten Ihnen die M&ouml;glichkeit, sich auf unserer Webseite &uuml;ber Ihren Google-Account zu registrieren. Eine zus&auml;tzliche Registrierung ist dann nicht erforderlich. Zur Anmeldung werden Sie auf die Seite der Google Inc. weitergeleitet, wo Sie sich mit ihren Nutzungsdaten anmelden k&ouml;nnen. Hierdurch werden ihr Google-Profil und unser Dienst verkn&uuml;pft. Durch die Verkn&uuml;pfung erhalten wir automatisch von der Google Inc. Daten aus Ihrem Profil. Folgende Informationen werden an uns &uuml;bermittelt:</p>
    <ul>
        <li>Ihr &ouml;ffentliches Profil (alles, was auch Dritte beim Aufruf Ihres Google-Profils ohne weiteres sehen und in Erfahrung bringen k&ouml;nnen einschlie&szlig;lich Ihres Profilbildes und Ihres Klarnamens)</li>
        <li>Ihre E-Mail-Adresse.</li>
        <li>Wenn Sie weitere Kontaktdaten, wie beispielsweise eine Telefonnummer, angegeben haben, so erhalten wir auch diese.</li>
    </ul>
    <p>Weitere Informationen zum Google-Login und den Privatsph&auml;re-Einstellungen entnehmen Sie bitte den&nbsp;<a href="https://www.google.com/policies/privacy/">Datenschutzhinweisen</a>&nbsp;und den&nbsp;<a href="https://www.google.com/intl/de/policies/terms/">Nutzungsbedingungen</a>&nbsp;der Google Inc.</p>
    <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
    <p>Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den Betrieb und die Wartung unserer Website als Auftragsverarbeiter t&auml;tig werden.</p>
    <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gel&ouml;scht, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerkl&auml;rung angegebenen Kontaktdaten.</p>
    <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gew&auml;hren.</p>
    <p><u>Kontaktformular</u></p>
    <p>Per Kontaktformular &uuml;bermittelte Daten werden einschlie&szlig;lich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu k&ouml;nnen oder um f&uuml;r Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</p>
    <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</p>
    <p>&Uuml;ber das Kontaktformular &uuml;bermittelte Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unber&uuml;hrt.</p>
    <p><u>Einbindung von digitalen Landkarten mithilfe von Google Maps</u></p>
    <p>Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend &bdquo;Google&ldquo;) betrieben. Dadurch k&ouml;nnen wir Ihnen interaktive Karten direkt in der Webseite anzeigen und erm&ouml;glichen Ihnen die komfortable Nutzung der Karten-Funktion.</p>
    <p>N&auml;here Informationen &uuml;ber die Datenverarbeitung durch Google k&ouml;nnen Sie&nbsp;<a href="http://www.google.com/privacypolicy.html">den Google-Datenschutzhinweisen</a> entnehmen. Dort k&ouml;nnen Sie im Datenschutzcenter auch Ihre pers&ouml;nlichen Datenschutz-Einstellungen ver&auml;ndern.</p>
    <p>Ausf&uuml;hrliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten<a href="http://www.dataliberation.org/">&nbsp;finden Sie hier</a>.</p>
    <p>Rechtsgrundlage f&uuml;r die Einbindung von Google Maps und dem damit verbundenen Daten-transfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
    <p>Durch den Besuch der Webseite erh&auml;lt Google Informationen, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabh&auml;ngig davon, ob Google ein Nutzerkonto bereitstellt, &uuml;ber das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.</p>
    <p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht w&uuml;nschen, m&uuml;ssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie f&uuml;r Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (selbst f&uuml;r nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks &uuml;ber Ihre Aktivit&auml;ten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Aus&uuml;bung dessen an Google richten m&uuml;ssen.</p>
    <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
    <p>Wenn Sie nicht m&ouml;chten, dass Google &uuml;ber unseren Internetauftritt Daten &uuml;ber Sie erhebt, verarbeitet oder nutzt, k&ouml;nnen Sie in Ihrem Browsereinstellungen JavaScript deaktivieren. In diesem Fall k&ouml;nnen Sie unsere Webseite jedoch nicht oder nur eingeschr&auml;nkt nutzen.</p>
    <p><u>Cookies</u></p>
    <p>Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endger&auml;t speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.</p>
    <p>Einige Cookies sind &ldquo;Session-Cookies.&rdquo; Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gel&ouml;scht. Hingegen bleiben andere Cookies auf Ihrem Endger&auml;t bestehen, bis Sie diese selbst l&ouml;schen. Solche Cookies helfen uns, Sie bei R&uuml;ckkehr auf unserer Website wiederzuerkennen. Mit einem modernen Webbrowser k&ouml;nnen Sie das Setzen von Cookies &uuml;berwachen, einschr&auml;nken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schlie&szlig;en des Programms von selbst gel&ouml;scht werden. Die Deaktivierung von Cookies kann eine eingeschr&auml;nkte Funktionalit&auml;t unserer Website zur Folge haben.</p>
    <p>Das Setzen von Cookies, die zur Aus&uuml;bung elektronischer Kommunikationsvorg&auml;nge oder der Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. f&uuml;r Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerkl&auml;rung separat behandelt.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Ihre Rechte als Betroffener gem&auml;&szlig; Datenschutz-Grundverordnung (DSGVO)</strong></p>
    <p><u>Widerruf Ihrer Einwilligung zur Datenverarbeitung</u></p>
    <p>Nur mit Ihrer ausdr&uuml;cklichen Einwilligung sind einige Vorg&auml;nge der Datenverarbeitung m&ouml;glich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt. </p>
    <p><u>Recht auf Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</u></p>
    <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Ver-sto&szlig;es, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personen-bezogenen Daten gegen die DSGVO verst&ouml;&szlig;t.</p>
    <p>Die Aufsichtsbeh&ouml;rde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdef&uuml;hrer &uuml;ber den Stand und die Ergebnisse der Beschwerde einschlie&szlig;lich der M&ouml;glichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
    <p><u>Recht auf Daten&uuml;bertragbarkeit</u></p>
    <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Au&szlig;erdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu &uuml;bermitteln, sofern</p>
    <ul>
        <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und</li>
        <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
    </ul>
    <p>In Aus&uuml;bung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen &uuml;bermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen d&uuml;rfen hierdurch nicht beeintr&auml;chtigt werden.</p>
    <p>Das Recht auf Daten&uuml;bertragbarkeit gilt nicht f&uuml;r eine Verarbeitung personenbezogener Daten, die f&uuml;r die Wahrnehmung einer Aufgabe erforderlich ist, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde.</p>
    <p><u>Recht auf Auskunft</u></p>
    <p>Sie k&ouml;nnen von dem Verantwortlichen eine Best&auml;tigung dar&uuml;ber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.</p>
    <p>Liegt eine solche Verarbeitung vor, k&ouml;nnen Sie von dem Verantwortlichen &uuml;ber folgende Informationen Auskunft verlangen:</p>
    <ul>
        <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
        <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
        <li>die Empf&auml;nger bzw. die Kategorien von Empf&auml;ngern, gegen&uuml;ber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt wer-den;</li>
        <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht m&ouml;glich sind, Kriterien f&uuml;r die Festlegung der Speicherdauer;</li>
        <li>das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</li>
        <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde;</li>
        <li>alle verf&uuml;gbaren Informationen &uuml;ber die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</li>
        <li>das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling gem&auml;&szlig; Art. 22 Abs. 1 und 4 DSGVO und &ndash; zumindest in diesen F&auml;llen &ndash; aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung f&uuml;r die betroffene Person.</li>
    </ul>
    <p>Ihnen steht das Recht zu, Auskunft dar&uuml;ber zu verlangen, ob die Sie betreffenden personen-bezogenen Daten in ein Drittland oder an eine internationale Organisation &uuml;bermittelt wer-den. In diesem Zusammenhang k&ouml;nnen Sie verlangen, &uuml;ber die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der &Uuml;bermittlung unterrichtet zu werden.</p>
    <p><u>Recht auf Berichtigung</u></p>
    <p>Sie haben ein Recht auf Berichtigung und/oder Vervollst&auml;ndigung gegen&uuml;ber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollst&auml;ndig sind. Der Verantwortliche hat die Berichtigung unverz&uuml;glich vorzunehmen.</p>
    <p><u>Recht auf Einschr&auml;nkung</u></p>
    <p>Unter den folgenden Voraussetzungen k&ouml;nnen Sie die Einschr&auml;nkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</p>
    <ul>
        <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen f&uuml;r eine Dauer bestreiten, die es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit der personenbezogenen Daten zu &uuml;berpr&uuml;fen;</li>
        <li>die Verarbeitung unrechtm&auml;&szlig;ig ist und Sie die L&ouml;schung der personenbezogenen Daten ablehnen und stattdessen die Einschr&auml;nkung der Nutzung der personenbezogenen Daten verlangen;</li>
        <li>der Verantwortliche die personenbezogenen Daten f&uuml;r die Zwecke der Verarbeitung nicht l&auml;nger ben&ouml;tigt, Sie diese jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, oder</li>
        <li>wenn Sie Widerspruch gegen die Verarbeitung gem&auml;&szlig; Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gr&uuml;nde des Verantwortlichen gegen&uuml;ber Ihren Gr&uuml;nden &uuml;berwiegen.</li>
    </ul>
    <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschr&auml;nkt, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.</p>
    <p>Wurde die Einschr&auml;nkung der Verarbeitung nach den o.g. Voraussetzungen eingeschr&auml;nkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschr&auml;nkung aufgehoben wird.</p>
    <p><u>Recht auf L&ouml;schung</u></p>
    <p> <em>L&ouml;schungspflicht</em></p>
    <p>Sie k&ouml;nnen von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht werden, und der Verantwortliche ist verpflichtet, diese Daten unverz&uuml;glich zu l&ouml;schen, sofern einer der folgenden Gr&uuml;nde zutrifft:</p>
    <ul>
        <li>Die Sie betreffenden personenbezogenen Daten sind f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
        <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO st&uuml;tzte, und es fehlt an einer anderweitigen Rechts-grundlage f&uuml;r die Verarbeitung.</li>
        <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung vor, oder Sie le-gen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
        <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtm&auml;&szlig;ig verarbeitet.</li>
        <li>Die L&ouml;schung der Sie betreffenden personenbezogenen Daten ist zur Erf&uuml;llung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
        <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gem&auml;&szlig; Art. 8 Abs. 1 DSGVO erhoben.</li>
    </ul>
    <p><em>Information an Dritte</em></p>
    <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten &ouml;ffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren L&ouml;schung verpflichtet, so trifft er unter Ber&uuml;cksichtigung der verf&uuml;gbaren Technologie und der Implementierungskosten angemessene Ma&szlig;nahmen, auch technischer Art, um f&uuml;r die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, dar&uuml;ber zu informieren, dass Sie als betroffene Per-son von ihnen die L&ouml;schung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>
    <p><em>Ausnahmen</em></p>
    <p>Das Recht auf L&ouml;schung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
    <ul>
        <li>zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information;</li>
        <li>zur Erf&uuml;llung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde;</li>
        <li>aus Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich der &ouml;ffentlichen Gesundheit gem&auml;&szlig; Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;</li>
        <li>f&uuml;r im &ouml;ffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder f&uuml;r statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, so-weit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unm&ouml;glich macht oder ernsthaft beeintr&auml;chtigt, oder</li>
        <li>zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</li>
    </ul>
    <p><u>Recht auf Unterrichtung</u></p>
    <p>Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden.</p>
    <p>Ihnen steht gegen&uuml;ber dem Verantwortlichen das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</p>
    <p>&nbsp;</p>
    <p><strong>SSL- bzw. TLS-Verschl&uuml;sselung</strong></p>
    <p>Aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschl&uuml;sselung. Damit sind Daten, die Sie &uuml;ber diese Website &uuml;bermitteln, f&uuml;r Dritte nicht mitlesbar. Sie erkennen eine verschl&uuml;sselte Verbindung an der &bdquo;https://&ldquo; Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>
    <p>&nbsp;</p>
    <p>Quelle: Datenschutz-Konfigurator von mein-datenschutzbeauftragter.de mit eigenen Anpassungen.</p>
</div>
