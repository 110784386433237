import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '../state.service';
import { ApiService } from '../api.service';
import { AuthService } from '../../../../auth/src/lib/auth.service';

@Component({
    selector: 'cna-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
    constructor(
        private state: StateService,
        private api: ApiService,
        public auth: AuthService
    ) {}

    ngOnInit(): void {
        this.api.get().subscribe(val => this.state.currentHelpers.next(val));
    }
    onPointSeleceted(val) {
        this.state.currentCoordinates.next(val.position);
        this.state.zoom.next(17);
    }

    callHotline() {
        window.location.href = 'tel:073140321290';
    }

    writeEmail() {
        window.location.href = 'mailto:euskirchenhilft@gmail.com';
    }


}
