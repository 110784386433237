<div class="ui-g">
    <header>
        <a [routerLink]="['/']">
            <img
                style="width: 100%"
                srcset="
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_2500/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg 2000w,
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_1800/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg 1800w,
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_1600/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg 1600w,
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_1400/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg 1400w,
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_1200/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg 1200w,
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_1000/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg 1000w,
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_800/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg   800w,
                    https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,w_600/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg   600w
                "
                src="https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/c_scale,h_1000,w_2500/v1585961735/WUN_hilft_Headerbild_Euskirchen_xbv33g.jpg"
            />
        </a>
    </header>
    <div class="ui-g-12 ">
        <router-outlet></router-outlet>
    </div>
    <div class="ui-g-12" style="text-align: center; background-color: #000000">
        <a class="ui-g ui-fluid">
            <div class="ui-g-6 ui-sm-12">
                <p style="font-size: x-small; margin-top: -1em; color: #ffffff">
                    powered by
                </p>
                <br />
                <a href="http://rrooaarr.com" target="_blank">
                    <img
                        style="max-height: 2rem"
                        src="https://res.cloudinary.com/rrooaarr-interactive-solutions/image/upload/f_auto,q_100/v1585226931/rrooaarr_logo_4c_mit_claim_er2wos.png"
                /></a>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <p>
                    <a
                        [routerLink]="['impressum']"
                        style="color: #fff; font-size: small; font-family: 'Open Sans';"
                        >Impressum</a
                    >
                </p>
                <p>
                    <a
                        [routerLink]="['datenschutz']"
                        style="color: #fff; font-size: small; font-family: 'Open Sans';"
                        >Datenschutzerklärung</a
                    >
                </p>
            </div>
        </a>
    </div>
</div>
