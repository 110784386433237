// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    googleMapsKey: 'AIzaSyCzZS0Hzq_UlXKjkR27qQdNb-POphHHBXo',
    hereMapsKey: 'zqetKUlkod2ffZu0hspgjmP_a4T7fD83u0BYU96pbdo',
    apiUrl: 'https://euskirchen-51-116-174-184.sslip.io',
    authConfig: {
        domain: 'euskirchen.eu.auth0.com',
        audience: 'https://euskirchen-51-116-174-184.nip.io',
        clientID: 'AMrg3HAI1IRMYNh5CTbA2E9rdZrXfaSD'
    },
    location: {
        lat: 50.6574392,
        lng: 6.7899945
    },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
